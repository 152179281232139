import React, {FC, useEffect, useState} from "react";
import {Box, Chip, Grid, ListItem, MenuItem, MenuList, Stack, Typography} from "@mui/material";
import {Flyby} from "./types";
import dayjs from "dayjs";
import {Line, LineChart} from "recharts";
import {Circle} from "@mui/icons-material";
import FlybyInfo from "./FlybyInfo";
import {useAuthDispatch} from "./AuthContext";
import {useWatcher} from "./WatcherContext";

require('dayjs/locale/ru')

const FlybysView: FC = () => {
    const [blinkStatus, setBlinkStatus] = useState<"success" | "error">(null);
    const [updated, setUpdated] = useState(true)
    const [flybys, setFlybys] = useState<Flyby[]>([])

    const [openedFlyby, setOpenedFlyby] = useState<Flyby>(null)

    const watcher = useWatcher();
    const authDispatch = useAuthDispatch();

    useEffect(() => {
        if (!watcher) {
            return
        }
        setBlinkStatus(null)
        fetch(`/api/flyby?watcherId=${watcher.id}`, {
            credentials: 'include',
        })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    authDispatch({type: 'fail'})
                }
            })
            .then(data => {
                setFlybys(data);
                setBlinkStatus("success")
                setUpdated(true)
                setTimeout(() => setBlinkStatus(null), 300)
            })
            .catch(reason => {
                console.log(`api error: ${reason}`)
                setBlinkStatus("error")
                setUpdated(false)
                setTimeout(() => setBlinkStatus(null), 300)
            })
        const timer = setInterval(() => {
            setBlinkStatus(null)
            fetch(`/api/flyby?watcherId=${watcher.id}`)
                .then(res => {
                    if (res.ok) {
                        return res.json()
                    }
                })
                .then(data => {
                    setFlybys(data)
                    setBlinkStatus("success")
                    setUpdated(true)
                    setTimeout(() => setBlinkStatus(null), 300)
                })
                .catch(reason => {
                    console.log(`api error: ${reason}`)
                    setBlinkStatus("error")
                    setUpdated(false)
                    setTimeout(() => setBlinkStatus(null), 300)
                })
        }, 5000)
        return () => {
            clearInterval(timer)
        }
    }, [watcher])

    const formatDates = (flyby: Flyby) => {
        const dateFrom = dayjs(flyby.dateTimeFrom)
        const dateTo = dayjs(flyby.dateTimeTo)
        if (flyby.powers.length === 1) {
            return dateFrom.format('HH:mm');
        }
        const diffHours = dateTo.diff(dateFrom, 'hour')
        const diffMinutes = dateTo.diff(dateFrom, 'minute') % 60
        const diffSeconds = dateTo.diff(dateFrom, 'second') % 60
        const diff = `${diffHours.toString().padStart(2, "0")}:${diffMinutes.toString().padStart(2, "0")}:${diffSeconds.toString().padStart(2, "0")}`
        return `${dateFrom.format('HH:mm')} (${diff})`
    }

    return (
        <>
            <Box height={1} overflow={'hidden'}>
                <Stack borderBottom={1} p={1}
                       borderColor={'divider'} spacing={4}
                       flexDirection={"row"}
                       alignItems={'baseline'}>
                    <Typography variant={"h6"}>Пролёты БПЛА
                        {blinkStatus && <Circle sx={{ml: '5px'}} fontSize={'small'} color={blinkStatus}/>}
                    </Typography>
                </Stack>

                <Grid container spacing={1} height={0.91}>
                    <Grid item xs={12} height={1}>
                        <Box borderBottom={1} p={1} borderColor={'divider'}>
                            <Grid container>
                                <Grid item xs={3}>
                                    <Typography>Время</Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography>Тип</Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography>Частоты</Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography>Наименования</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography>Мощность сигнала</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                        <MenuList sx={{height: 0.9, overflowY: 'auto'}}>
                            {
                                flybys.map((flyby, ind, array) => (
                                    <MenuItem
                                        key={flyby.id}
                                        divider
                                        disableRipple
                                        onClick={() => {
                                            setOpenedFlyby(flyby)
                                        }}
                                        sx={{
                                            padding: 0,
                                            backgroundColor: updated && flyby.isActual ? (
                                                flyby.isAlarm ? 'tomato' :
                                                    flyby.isWarning ? 'gold' :
                                                        'lightgreen'
                                            ) : 'inherit'
                                        }}>
                                        <ListItem>
                                            <Grid container padding={0}>
                                                <Grid item xs={3} overflow={'hidden'}>
                                                    <Typography>{formatDates(flyby)}</Typography>
                                                </Grid>
                                                <Grid item xs={1} overflow={'hidden'}>
                                                    <Typography>{flyby.type}</Typography>
                                                </Grid>
                                                <Grid item xs={1} overflow={'hidden'}>
                                                    <Stack spacing={1}
                                                           direction={'row'}>{flyby.freqs.map((freq) => <Chip
                                                        label={freq}
                                                        size={'small'}/>)}</Stack>
                                                </Grid>
                                                <Grid item xs={5} overflow={'hidden'}>
                                                    <Stack spacing={1}
                                                           direction={'row'}>{flyby.names.map((name) => <Chip
                                                        label={name} size={'small'}/>)}</Stack>
                                                </Grid>
                                                <Grid item xs={2} overflow={'hidden'}>
                                                    <LineChart data={flyby.powers.map((power) => ({"p": power}))}
                                                               compact={true}
                                                               height={20}
                                                               width={100}>
                                                        <Line type={"monotone"}
                                                              isAnimationActive={false}
                                                              dot={false}
                                                              dataKey="p"/>
                                                    </LineChart>
                                                </Grid>
                                            </Grid>
                                        </ListItem>
                                    </MenuItem>
                                ))
                            }
                        </MenuList>
                    </Grid>
                </Grid>
            </Box>
            {openedFlyby && <FlybyInfo key={openedFlyby.id} flyby={openedFlyby} onClose={() => setOpenedFlyby(null)}/>}
        </>
    )
}

export default FlybysView;
